<template>
  <footer>

    <b-container fluid>
      <Prefooter />
      <PreFooterWhite />
    </b-container>

    <b-container fluid v-if="mixinIsPublic() && this.$route.name !== 'vivamod-business'">
      <PromoRow />
    </b-container>

    <div class="footer-info">
      <b-container fluid>
        <div class="footer-logo">

          <PoweredBy v-if="mainLogoSrc != '' || (poweredByLogo !== false && poweredByLogo != '')"/>

          <div v-if="mainLogoSrc == '' && footerLogoSrc == ''">
            <router-link :to="{name: 'homepage'}"><SvgFooterLogo /></router-link>
          </div>

          <div v-else-if="footerLogoSrc != ''">
            <router-link :to="{name: 'homepage'}">
              <img :src="footerLogoSrc"/>
            </router-link>
          </div>

          <div v-else-if="mainLogoSrc != ''">
            <router-link :to="{name: 'homepage'}">
              <img :src="mainLogoSrc"/>
            </router-link>
          </div>

        </div>

        <!-- links footer vadobay -->
        <ul class="footer-menu" v-if="isVadobay">
          <li>
            <router-link :to="{name: 'about'}">Chi Siamo</router-link>
          </li>
          <li>
            <router-link :to="{name: 'customer-care', hash:'#assistenza'}">
              Contatti
            </router-link>
          </li>

          <li v-if="isVadobay">
            <a href="https://www.salabamsolutions.com" target="_blank">Salabam Solutions</a>
          </li>

          <li>
            <router-link :to="{name: 'blog'}">
              Blog
            </router-link>
          </li>

          <li>
            <a :href="voliVadobayHost" title="" target="_blank">fly by VadoBay</a>
          </li>

          <li v-if="isAmbalagi">
            <!--<router-link :to="{name: 'vivamod-business'}">
              Servizi Business
            </router-link>-->
            <a href="https://business.vivamod.com/" title="Soluzioni di online travel booking per il loyalty, il marketing e i regali aziendali" target="_blank">Servizi Business</a>
          </li>

          <li><a :href="site.urlPrivacy" target="_blank">Privacy Policy</a></li>
          <li><a :href="site.urlCookiePolicy" target="_blank">Cookie Policy</a></li>
          <li>
            <router-link :to="{name: 'terms'}">
              Condizioni d'uso
            </router-link>
          </li>
        </ul>

        <!-- links footer ambalagi/vivamod -->
        <div class="links" v-if="isAmbalagi">
          <br/><br/>
          <div class="panel-gray">
            <b-container fluid>
              <b-row class="linkList">
                <b-col sm="12" md="4" lg="4">
                  <div class="footerBlock">
                    <p class="linkTitle">
                      Viaggia con Vivamod
                    </p>
                    <router-link :to="{name: 'homepage'}">Prenota!</router-link>
                    <router-link :to="{name: 'dc-sso'}">Ho un buono</router-link>
                    <!--<router-link :to="{name: 'covid19'}" class="">Covid-19</router-link>-->
                    <router-link :to="{name: 'perche-scegliere-vivamod', hash: '#perche-scegliere-vivamod'}">Viaggiare con {{site.brand}}</router-link>
                    <!--<router-link :to="{name: 'sistemi-di-pagamento'}">Buoni sconto e ticket</router-link>-->
                    <router-link :to="{name: 'faq'}">Domande frequenti</router-link>
                    <router-link :to="{name: 'blog'}">Blog</router-link>
                  </div>
                </b-col>

<!--                <b-col sm="12" md="4" lg="4">
                  <div class="footerBlock">
                    <p class="linkTitle">
                      Soluzioni business
                    </p>

                    <a :href="businessVivamodHost" target="_blank">Vivamod business</a>
                    <a :href="businessVivamodHost + 'loyalty'" target="_blank">Soluzioni loyalty</a>
                    <a :href="businessVivamodHost + 'concorsi-premi'" target="_blank">Soluzioni per concorsi</a>
                    <a :href="businessVivamodHost + 'regali-aziendali'" target="_blank">Regali aziendali</a>
                    <a :href="businessVivamodHost + 'viaggi-incentive'" target="_blank">Soluzioni per incentive</a>
                    <a :href="businessVivamodHost + 'soluzioni-welfare'" target="_blank">Welfare aziendale</a>
                    <router-link :to="{name: 'adv'}">Agenzia viaggi by Vivamod</router-link>
                  </div>

                </b-col>-->

                <b-col sm="12" md="4" lg="4">

                  <div class="footerBlock">
                    <p class="linkTitle">
                      Vivamod | modern travel
                    </p>

                    <router-link :to="{name: 'contacts', hash: '#assistenza'}">Contatti</router-link>
                    <a :href="site.urlPrivacy" target="_blank">Privacy Policy</a>
                    <a :href="site.urlCookiePolicy" target="_blank">Cookie Policy</a>
                    <router-link :to="{name: 'terms'}">Condizioni d'uso</router-link>
                  </div>

                </b-col>

                <b-col sm="12" md="4" lg="4">

                  <div class="footerBlock">
                    <p class="linkTitle">
                      Chi Siamo
                    </p>
                    <router-link :to="{name: 'about'}">Vivamod di Salabam Solutions</router-link>
                    <a href="https://www.salabamsolutions.com" target="_blank">Salabam Solutions</a>
                  </div>

                </b-col>

              </b-row>
            </b-container>

          </div>
        </div>




        <div class="footer-bottom">
          <a href="#">© SALABAM SRL - P.IVA: 04238550406</a>
          <a href="http://www.taiview.com" target="_blank">Ui & Ux Design: taiview.com</a>
          <div>
            <a v-if="site.facebook != ''" :href="site.facebook" target="_blank"><Facebook /></a>
            <a v-if="site.linkedin != ''" :href="site.linkedin" target="_blank"><Linkedin /></a>
            <a v-if="site.instagram != ''" :href="site.instagram" target="_blank"><Instagram /></a>
            <a href="/blog"><SvgBlog /></a>
          </div>
        </div>
      </b-container>
    </div>

    <back-to-top bottom="50px" right="50px" visibleoffset="600">
      <b-button class="btn-rounded" variant="secondary">
        <SvgChevronUp />
      </b-button>
    </back-to-top>

  </footer>
</template>

<script>

import Vue from "vue"
import Facebook from '../svg/facebook'
import Instagram from '../svg/instagram'
import SvgChevronUp from '../svg/chevron-up'
import Linkedin from '../svg/linkedin'
import BackToTop from 'vue-backtotop'
import PromoRow from '@/components/blocks/promo-row'
//import Prefooter from '../blocks/common/prefooter'
//import PrefooterWhite from '../blocks/common/prefooter-white'

export default {
  name: 'page-footer',
  components:
      {
        Facebook, Instagram, Linkedin, BackToTop, SvgChevronUp, PromoRow,
        Prefooter: () => import('../blocks/common/prefooter').then(function(Prefooter) {
          window.console.log('async: Prefooter Component loaded')
          return Prefooter
        }),
        PreFooterWhite: () => import('../blocks/common/prefooter-white').then(function(PreFooterWhite) {
          window.console.log('async: PreFooterWhite Component loaded')
          return PreFooterWhite
        }),
        //SvgLogoPoweredByCopy: () => import('@/components/' + process.env.VUE_APP_CUSTOM_SRC + 'svg/logoPoweredByCopy'),
        PoweredBy: () => import('../blocks/common/powered-by').then(function(PoweredBy) {
          window.console.log('async: PoweredBy Component loaded')
          return PoweredBy
        }),
        SvgFooterLogo: () => import('@/components/' + process.env.VUE_APP_CUSTOM_SRC + 'svg/footerLogo')
            .then(function(SvgFooterLogo) {
              window.console.log('async: SvgFooterLogo Component loaded')
              return SvgFooterLogo
            }),
        SvgBlog: () => import('@/components/' + process.env.VUE_APP_CUSTOM_SRC + 'svg/blog')
            .then(function(SvgFooterLogo) {
              window.console.log('async: SvgFooterLogo Component loaded')
              return SvgFooterLogo
            }),
      },
  props: {
    footerMenu: String,
    btnText: String,
  },
  data (){
    return {
      config: Vue.prototype.$config,
      site: Vue.prototype.$config.site,
      poweredByLogo: (Vue.prototype.$config.guiSettings.poweredByLogo !== undefined)? Vue.prototype.$config.guiSettings.poweredByLogo : '',
      isVadobay: (process.env.VUE_APP_NAME == 'vadobay'),
      isAmbalagi: (process.env.VUE_APP_NAME == 'ambalagi'),
      businessVivamodHost: process.env.VUE_APP_BUSINESS_VIVAMOD_HOST,
      voliVadobayHost: process.env.VUE_APP_VOLI_VADOBAY_HOST,
    }
  },
  computed: {
    mainLogoSrc: {
      get(){

        // il logo personalizzato può stare sia in config.guiSettings sia in integration
        // quello in integration ha la precedenza
        // quello in guiSettings serve principalmente per salabam sito pubblico
        // per personalizzazioni tramite link
        if ( Vue.prototype.$config.integration && Vue.prototype.$config.integration.mainLogoSrc ) return Vue.prototype.$config.integration.mainLogoSrc;
        if ( Vue.prototype.$config.guiSettings.mainLogoSrc ) return Vue.prototype.$config.guiSettings.mainLogoSrc
        return '';
      },
      set(){},
    },
    footerLogoSrc: {
      get(){

        // il logo personalizzato può stare sia in config.guiSettings sia in integration
        // quello in integration ha la precedenza
        // quello in guiSettings serve principalmente per salabam sito pubblico
        // per personalizzazioni tramite link
        if ( Vue.prototype.$config.integration && Vue.prototype.$config.integration.footerLogoSrc ) return Vue.prototype.$config.integration.footerLogoSrc;
        if ( Vue.prototype.$config.guiSettings.footerLogoSrc ) return Vue.prototype.$config.guiSettings.footerLogoSrc
        return '';
      },
      set(){},
    },
  },
}
</script>


<style lang="scss">





footer
{
  .footer-info
  {
    position:relative;
    overflow:hidden;
    .poweredBy
    {
      left:50%;
      right:auto;
      top:0;
      transform-origin: top center;;
      transform:translateX(-50%) scale(0.75);
      margin:0 auto;
      position:absolute;
      //background:$light-gray;
    }
  }
}

.footer-info
{
  text-align:center;
  padding-top:90px;
  padding-bottom:60px;
  background:$white;

  .footer-menu a
  {
    //@include dynamic-colors-blend();
    @include theme-text-footer();
  }
}

.footer-logo
{
  display:flex;
  justify-content:center;
  img
  {
    max-width: 220px;
    margin:1rem auto;
  }
}

.footer-menu
{
  display:flex;
  flex-direction: column;
  margin:50px auto;
  justify-content: center;
  li
  {
    margin: 15px 0;

    @include media-breakpoint-up(lg) {
      margin: 0;
    }

    &:not(last-child)
    {
      //border-right:1px solid $black;
    }
  }

  a
  {
    //@include customize-color("white");
    color:inherit !important;
    //@include dynamic-colors-blend();
    @extend [theme-text-footer] !optional;
  }
}

.footer-bottom
{
  display:flex;
  flex-direction: column;
  font-size:14px;
  padding-top:70px;
  padding-bottom:70px;
  align-items: center;
  & > *:not(:last-child)
  {
    margin-bottom:20px;
  }
  a
  {
    //@include customize-border-color("white");
    color:inherit !important;
    //@include dynamic-colors-blend();
    @extend [theme-text-footer] !optional;
  }
  svg
  {
    margin:0 10px;
    path
    {
      //@include customize-fill("white");
      //@extend [custom-fill-light] !optional;
    }
  }
}

@include media-breakpoint-up(lg)
{
  .footer-menu
  {
    display:flex;
    flex-direction: row;
    margin:50px 0 100px 0;
    li
    {
      &:not(:last-child) a
      {
        margin-right:20px;
        padding-right:20px;
        border-right:1px solid;
      }
    }
  }

  .footer-bottom
  {
    flex-direction:row;
    justify-content:space-between;
    & > *:not(:last-child)
    {
      margin-bottom:0;
    }
    & > *
    {
      flex:33%;
      width:33%;
      text-align:center;
    }
  }
}

.btn-to-top {
  width: 60px;
  height: 60px;
  padding: 10px 16px;
  border-radius: 50%;
  font-size: 22px;
  line-height: 22px;
}

.vue-back-to-top
{
  z-index:10 !important;
  .svg
  {
    //@include dynamic-colors-blend();
  }
}

.links
{

  text-align: left;

  .linkTitle, .linkTitle a
  {
    color:$black;
    font-size: 16px!important;
    font-weight: 600;
    margin-bottom:1rem!important;
  }
  .linkList a
  {
    color:$black;
    font-size: 13px;
    font-weight: 600;
    display:block;
    margin-bottom:0.5rem;
  }

  .col-lg-4
  {
    margin-bottom: 20px;

    @include media-breakpoint-up(lg){
      display: flex;
      justify-content: center;
      margin-bottom: 0;
    }
  }
}

</style>