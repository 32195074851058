<template>
    <section class="promo-row">
        <div class="left"><img src="../../../public/assets/images/promo-left.png" /></div>
        <div class="right">
            <svg width="954" height="794" viewBox="0 0 954 794" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M902.343 793.86H51.7562C20.5933 793.86 -3.49186 766.5 0.416703 735.549L87.2501 45.3182C90.5249 19.4372 112.497 0 138.59 0H902.238C930.865 0 954 23.2401 954 51.762V742.098C954.105 770.62 930.865 793.86 902.343 793.86Z" fill="#39414E"/>
            <path d="M902.343 793.86H51.7562C20.5933 793.86 -3.49186 766.5 0.416703 735.549L87.2501 45.3182C90.5249 19.4372 112.497 0 138.59 0H902.238C930.865 0 954 23.2401 954 51.762V742.098C954.105 770.62 930.865 793.86 902.343 793.86Z" fill="url(#promo-row-gradient)" fill-opacity="0.9"/>
            <path d="M740.1 719.8L953.6 506.3V391.1C951.6 392.5 949.7 394.1 947.9 395.9L682 661.7C666 677.7 666 703.7 682 719.8C690 727.8 700.5 731.8 711 731.8C721.5 731.8 732.1 727.8 740.1 719.8Z" fill="url(#paint1_linear-promo-row)" fill-opacity="0.1"/>
            <path d="M755 549.7L953.6 351.1V235L696.9 491.7C680.9 507.7 680.9 533.7 696.9 549.8C704.9 557.8 715.4 561.8 725.9 561.8C736.4 561.8 746.9 557.8 755 549.7Z" fill="url(#paint2_linear-promo-row)" fill-opacity="0.1"/>
            <path d="M739 152C739 212.768 689.768 262 629 262C568.232 262 519 212.768 519 152C519 91.2313 568.232 42 629 42C689.768 42 739 91.2313 739 152Z" fill="#EDF0F7" fill-opacity="0.1"/>
            <path d="M450 197C450 221.86 429.86 242 405 242C380.14 242 360 221.86 360 197C360 172.14 380.14 152 405 152C429.86 152 450 172.14 450 197Z" fill="#EDF0F7" fill-opacity="0.1"/>
            <defs>
            <linearGradient id="promo-row-gradient" x1="477" y1="794" x2="477" y2="-3.46879e-06" gradientUnits="userSpaceOnUse">
            <stop class="first-color" stop-color="#FF974F"/>
            <stop class="last-color" offset="1" stop-color="#FF5652"/>
            </linearGradient>
            <linearGradient id="paint1_linear-promo-row" x1="1039.6" y1="399.8" x2="619.152" y2="597.529" gradientUnits="userSpaceOnUse">
            <stop offset="0.0528433" stop-color="white"/>
            <stop offset="1" stop-color="#EDF0F7" stop-opacity="0.75"/>
            </linearGradient>
            <linearGradient id="paint2_linear-promo-row" x1="993.6" y1="239.8" x2="633.563" y2="432.39" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="#EDF0F7" stop-opacity="0.75"/>
            </linearGradient>
            </defs>
            </svg>
            <img src="../../../public/assets/images/promo-right.png" />
            <div class="right--content">
                <p>
                  Scopri le nostre soluzioni travel tech per l'HR e il marketing della tua azienda.
                </p>
                <!--<a href="#" class="btn btn-light">Scopri</a>-->
                <a href="https://www.salabamsolutions.com" target="_blank" title="Soluzioni di online travel booking per il loyalty, il marketing e i regali aziendali" class="btn btn-cta">Scopri</a>
            </div>
        </div>
    </section>
</template>

<script>

import Vue from "vue"

export default {
  name: 'promo-row',
  data(){
    return {
      config: Vue.prototype.$config
    }
  }
}
</script>

<style lang="scss">
    
    
    
    

    .promo-row
    {
        text-align: center;
        max-width:1600px;
        margin:auto;
        margin-bottom:60px;
        .left,.right
        {
            display: inline-block;
            position:relative;
            max-width: 400px;
            margin:auto;
            margin-bottom:60px;
        }
        .right
        {
            position:relative;
            svg
            {
                position:absolute;
                top:0;
                left:0;
                width:auto;
                height:100%;
                z-index: 1;
                opacity:0.95;
                @include customize-gradient-svg();
            }
            &--content
            {
                //@include customize-color("light");
                color:$white;
                @include customize-gradient-text();
                text-align: left;
                position:absolute;
                margin:auto;
                top:50%;
                width:60%;
                max-width: 440px;
                left:0;
                right:0;
                transform:translateY(-50%) translateX(20%);
                z-index:1;
                font-weight: 400;
                font-size: 22px;
                a
                {
                    margin-top:2rem;
                    display:block;
                    max-width: 220px;
                    color:$white;
                }
                @include media-breakpoint-up(lg)
                {
                    font-size: 30px;
                }
                @include media-breakpoint-up(xxl)
                {
                    width:75%;
                    font-size: 38px;
                    transform:translateY(-50%) translateX(25%);
                    a
                    {
                        margin-top:3rem;
                    }
                }
            }
        }
        @include media-breakpoint-up(md)
        {
            margin-bottom:180px;
            margin-top:180px;
            display: flex;
            .left
            {
                transform:translateX(9.95%) translateY(4.85%);
                z-index: 1;
                max-width: none;
            }
            .right
            {
                transform:translateX(-9.95%) translateY(-4.85%);
                max-width: none;
            }
        }
    }
</style>